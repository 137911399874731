<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <span class="text-h5">Importar Usuários</span>
        <v-spacer></v-spacer>
        <UploadExcel @conteudo-excel="conteudoExcel" />
      </v-card-title>
      <v-card-text class="d-flex">
        <v-icon color="primary" class="mr-2">mdi-coffee</v-icon>
        <v-progress-linear
          :value="valueDeterminate"
          :background-opacity="0.5"
          color="xbColor"
          height="40px"
          rounded
          class=""
        >
          <template v-slot="{}">
            <strong> {{ excelData.length }} usuarios restantes </strong>
          </template>
        </v-progress-linear>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!adicionandoUsers"
          color="primary"
          @click="cadastrarUsuarios"
          class="white--text ml-4"
          height="40px"
          depressed
          :disabled="adicionandoUsers"
        >
          <v-icon left>mdi-account-plus-outline</v-icon>
          Cadastrar Usuários
        </v-btn>
        <v-btn
          v-if="adicionandoUsers"
          @click="pararGeracao = true"
          color="secondary"
          class="white--text ml-4"
          depressed
          height="40px"
        >
          <v-icon left>mdi-pause-circle-outline</v-icon>
          Pausar
        </v-btn>
      </v-card-text>
      <v-card outlined class="mt-6">
        <v-card-text>
          <v-data-table
            v-if="!loadingLista"
            :headers="headers"
            :items="excelData"
            :item-class="row_classes"
            :items-per-page="10"
            class="data-tables data-tables__row-click"
          >
            <template v-slot:item.seq="{ item }">
              <v-progress-circular
                v-if="item.gravando"
                indeterminate
                color="xbColor"
                width="5"
                class="mr-2"
                :size="25"
              ></v-progress-circular>
              {{ item.seq }}
            </template>
            <template v-slot:item.empreendedor="{ item }">
              <span v-if="item.empreendedor === 1">SIM</span>
              <span v-else>NÃO</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { postUsuario } from "@/api/admin/usuarios.js";

export default {
  name: "UploadUsuarios",

  data() {
    return {
      excelData: [],
      loadingLista: false,
      valueDeterminate: 0,
      adicionandoUsers: false,
      pararGeracao: false,
    };
  },

  components: {
    UploadExcel: () => import("../../../helpers/UploadExcel.vue"),
  },

  computed: {
    headers() {
      return [
        {
          text: "",
          width: "90px",
          value: "seq",
        },
        {
          text: "Email",
          value: "EMAIL",
        },
        {
          text: "Nome",
          value: "NOME",
        },
        {
          text: "Documento",
          value: "DOCUMENTO",
        },
        {
          text: "Documento",
          value: "TELEFONE",
        },
      ];
    },
  },

  watch: {},

  methods: {
    row_classes(item) {
      if (item.gravando) {
        return ["xbColor--text", "font-weight-bold"]; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    conteudoExcel(data) {
      this.excelData = data;
      this.excelData.map((item, index) => {
        item.seq = index + 1;
        item.gravando = false;

        return item;
      });
    },

    devolveUsuario(excelData) {
      let documento = excelData.DOCUMENTO.toString();
      documento = documento.replace(/\D/g, "");

      let usuario = {};
      usuario.password = documento;
      usuario.password_confirmation = documento;
      usuario.email = excelData.EMAIL;
      usuario.nome = excelData.NOME;
      usuario.documento = documento;
      usuario.celular = excelData.TELEFONE;
      usuario.role_id = 2;
      usuario.status = 1;
      return usuario;
    },

    async cadastrarUsuarios() {
      try {
        let total = this.excelData.length - 1;
        if (this.excelData.length) {
          this.adicionandoUsers = true;
          for (let i = 0; i <= total; i++) {
            if (!this.pararGeracao) {
              this.excelData[0].gravando = true;
              this.$Progress.start();
              const response = await postUsuario(
                this.devolveUsuario(this.excelData[0])
              );
              if (response.status === 201) {
                this.valueDeterminate = (i / total) * 100;
                this.$Progress.finish();
                this.excelData.splice(0, 1);
              } else if (response.status === 200) {
                console.log(this.excelData[0].NOME);
                this.valueDeterminate = (i / total) * 100;
                this.$Progress.finish();
                this.excelData.splice(0, 1);
              }
            } else {
              i = total;
            }
          }
          if (this.pararGeracao) {
            this.$toast.success("Importação de usuários pausado");
            this.valueDeterminate = 0;
          } else {
            this.$toast.success("Importação de usuários finalizado");
          }
          this.adicionandoUsers = false;
          this.pararGeracao = false;
        }
      } catch (error) {
        console.log(error);
        if (error.status === 406 || error.status === 422) {
          this.$Progress.finish();
          this.pararGeracao = true;
          this.valueDeterminate = 0;
          this.adicionandoUsers = false;
        }
      }
    },
  },
};
</script>

<style></style>
